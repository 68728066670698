<template>
  <v-card
    v-resizable="onResize"
    resizable-top
    class="d-flex flex-column fill-height no-user-select resizable-wrapper"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-data-table
      v-model="displayedCrossroads"
      :height="height"
      :headers="headers"
      :items="crossroads"
      show-select
      dense
      hide-default-footer
      fixed-header
    >
      <template
        v-slot:header.data-table-select="{ on, props }"
      >
        <v-btn
          icon
          v-on="on"
          @click="() => on.input(!props.value) && toggleCrossroadsDisplay(!props.value)"
        >
          <v-icon small>
            {{ props.value ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
        </v-btn>
      </template>

      <template
        v-slot:header.actions
      >
        <v-btn
          icon
          @click="expand.crossroads = !expand.crossroads"
        >
          <v-icon>
            {{ expand.crossroads ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </template>

      <template
        v-slot:body="{ items }"
      >
        <tbody
          v-if="expand.crossroads"
        >
          <tr
            v-for="(crossroad, i) in items"
            :key="`crossroad-${i}`"
          >
            <td>
              <v-btn
                icon
                class="ml-5"
                @click="() => toggleCrossroadDisplay(crossroad)"
              >
                <v-icon small>
                  {{ isCrossroadDisplayed(crossroad) ? 'mdi-eye' : 'mdi-eye-off' }}
                </v-icon>
              </v-btn>
            </td>
            <td>
              {{ getCrossroadName(crossroad) }}
            </td>
            <td class="text-center">
              <o-chip-station-disconnected
                v-if="!crossroad.station.connected"
                small
                @click="$emit('crossroad-click', crossroad.id)"
              />

              <o-chip-station-synchronization
                v-else-if="crossroad.station.synchronization !== 'SYNCHRONIZATION_DONE'"
                :synchronization="crossroad.station.synchronization"
                small
                :to="{
                  name: 'configuration_stations',
                  query: {
                    stationId: crossroad.station.id,
                  },
                }"
              />

              <o-chip-crossroad-out-of-control
                v-else-if="!crossroad.inControl"
                small
                @click="$emit('crossroad-click', crossroad.id)"
              />

              <template v-else>
                <o-chip-crossroad-state
                  small
                  :mode="getCrossroadRealMode(crossroad)"
                  :traffic-light-plan="getCrossroadRealTrafficLightPlan(crossroad)"
                  @click="$emit('crossroad-click', crossroad.id)"
                />
                <span
                  v-if="areCrossroadStatesDifferent(crossroad)"
                >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                  <o-chip-crossroad-state
                    small
                    :mode="crossroad.userState && crossroad.userState.mode"
                    :traffic-light-plan="crossroad.userState !== null && crossroad.userState.trafficLightPlan !== null ? crossroad.userState.trafficLightPlan.number : null"
                    theoretical
                    @click="$emit('crossroad-click', crossroad.id)"
                  />
                </span>
              </template>
            </td>
            <td class="text-center">
              <template v-if="!crossroad.station.connected">
                <template v-if="crossroad.station.connectedChangedAt">
                  {{ moment.utc(crossroad.station.connectedChangedAt).local().format('L LTS') }}
                </template>
              </template>
              <template v-else-if="!crossroad.inControl">
                <template v-if="crossroad.inControlChangedAt">
                  {{ moment.utc(crossroad.inControlChangedAt).local().format('L LTS') }}
                </template>
              </template>
              <template v-else-if="crossroad.userState !== null && crossroad.userState.since !== null">
                {{ moment.utc(crossroad.userState.since).local().format('L LTS') }}
              </template>
            </td>
            <td class="text-center">
              <v-btn
                icon
                :disabled="crossroad.location === null"
                @click="() => $emit('crossroad-locate', crossroad.id)"
              >
                <v-icon small>
                  mdi-target
                </v-icon>
              </v-btn>
              <v-btn
                icon
                :to="{
                  name: 'configuration_stations',
                  query: {
                    crossroadId: crossroad.id,
                  },
                }"
              >
                <v-icon small>
                  mdi-cog
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { getCrossroadName } from '@utils/crossroad';

  export default {
    props: {
      crossroads: {
        type: Array,
        required: true,
      },
      show: {
        type: Object,
        required: false,
        default: () => ({
          crossroads: [],
        }),
      },
    },

    data: function () {
      return {
        height: 200,
        displayedCrossroads: [],
        expand: {
          crossroads: true,
        },
        headers: [
          {
            text: this.$t('messages.views.admin.components.dashboard.stations.crossroads'),
            value: 'name',
            sortable: true,
          },
          {
            text: this.$t('labels.crossroad.mode'),
            value: 'mode',
            sortable: false,
            align: 'center',
          },
          {
            text: this.$t('messages.views.admin.components.dashboard.stations.since'),
            value: 'since',
            sortable: true,
            align: 'center',
          },
          {
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      };
    },

    mounted () {
      this.displayedCrossroads = this.crossroads;
      this.height = 36 + 37 * this.crossroads.length;
      if (this.height > 200) {
        this.height = 200;
      }
    },

    methods: {
      areCrossroadStatesDifferent (crossroad) {
        if (
          (!crossroad.userState || !crossroad.userState.mode) &&
          (!crossroad.stationState || !crossroad.stationState)
        ) {
          return false;
        }

        if (!crossroad.userState) {
          return !!crossroad.stationState;
        }

        if (!crossroad.stationState) {
          return !!crossroad.userState;
        }

        switch (crossroad.userState.mode) {
          case 'MODE_TIMELINE':
            if (!crossroad.stationWatcherState || crossroad.stationWatcherState.mode !== 'MODE_TIMELINE') {
              return true;
            }

            return (!crossroad.stationState || crossroad.stationState.mode !== 'MODE_TRAFFIC_LIGHT_PLAN');
          case 'MODE_SMARTWAY':
            if (!crossroad.stationWatcherState || crossroad.stationWatcherState.mode !== 'MODE_SMARTWAY') {
              return true;
            }

            return (!crossroad.stationState || crossroad.stationState.mode !== 'MODE_TRAFFIC_LIGHT_PLAN');
          case 'MODE_TRAFFIC_LIGHT_PLAN':
            if (!crossroad.stationState || crossroad.stationState.mode !== 'MODE_TRAFFIC_LIGHT_PLAN') {
              return true;
            }

            if (crossroad.stationState.trafficLightPlan === null) {
              return crossroad.userState.trafficLightPlan !== null;
            }
            if (crossroad.userState.trafficLightPlan === null) {
              return true;
            }

            return crossroad.stationState.trafficLightPlan.number !== crossroad.userState.trafficLightPlan.number;
          case 'MODE_BLINKING':
          case 'MODE_EXTINCTION':
            return !crossroad.stationState || crossroad.stationState.mode !== crossroad.userState.mode;
        }
      },
      toggleCrossroadDisplay (crossroad) {
        if (this.isCrossroadDisplayed(crossroad)) {
          this.displayedCrossroads.splice(this.displayedCrossroads.indexOf(crossroad), 1);
          this.$emit('crossroad-hide', crossroad.id);
        } else {
          this.displayedCrossroads.push(crossroad);
          this.$emit('crossroad-show', crossroad.id);
        }
      },
      isCrossroadDisplayed (crossroad) {
        return this.displayedCrossroads.indexOf(crossroad) !== -1;
      },
      toggleCrossroadsDisplay (display) {
        for (const crossroad of this.crossroads) {
          this.show.crossroads[crossroad.letter] = display;
        }
        if (display) {
          this.$emit('crossroad-show-all');
        } else {
          this.$emit('crossroad-hide-all');
        }
      },
      getCrossroadName (crossroad) {
        return getCrossroadName(crossroad);
      },
      onResize (payload) {
        this.height = payload.height - 5;
        this.$emit('resize', payload.resizing);
      },

      getCrossroadRealMode (crossroad) {
        if (crossroad.stationState && crossroad.stationState.mode) {
          switch (crossroad.stationState.mode) {
            case 'MODE_TRAFFIC_LIGHT_PLAN':
              if (crossroad.stationWatcherState && crossroad.stationWatcherState.mode) {
                switch (crossroad.stationWatcherState.mode) {
                  case 'MODE_TRAFFIC_LIGHT_PLAN':
                    return 'MODE_TRAFFIC_LIGHT_PLAN';
                  case 'MODE_TIMELINE':
                    return 'MODE_TIMELINE';
                  case 'MODE_SMARTWAY':
                    return 'MODE_SMARTWAY';
                  default:
                    return null;
                }
              } else {
                return 'MODE_TRAFFIC_LIGHT_PLAN';
              }
            default:
              return crossroad.stationState.mode;
          }
        } else {
          return null;
        }
      },

      getCrossroadRealTrafficLightPlan (crossroad) {
        if (crossroad.stationState && crossroad.stationState.trafficLightPlan) {
          return crossroad.stationState.trafficLightPlan.number;
        } else {
          return null;
        }
      },
    },
  };
</script>

<style scoped lang="sass">
  .resizable-wrapper
    border-top: 5px solid var(--v-primary-base)
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
</style>
